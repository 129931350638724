<template>
  <div class="main">
    <el-form :label-position="'left'" label-width="120px" style="margin: 20px; width: 90%; min-width: 600px">
      <el-form-item label="id" v-if="review && review.courseExtendReview">
        {{ review.courseExtendReview.id }}
      </el-form-item>
      <el-form-item label="hasReview" v-if="review && review.courseExtendReview">
        {{ review.courseExtendReview.hasReview }}
      </el-form-item>

      <el-form-item label="用户手机号" v-if="review && review.user">
        {{ review.user.userPhone }}
      </el-form-item>
      <el-form-item label="老的课程" v-if="review && review.courseExtendReview">
        {{ review.oldCourse.courseId }}, {{ review.oldCourse.courseName }}
      </el-form-item>

      <el-form-item label="所有订单" v-if="review && review.allOrderList">
        <div v-for="(item, index) in review.allOrderList" :key="index">
          {{ item.id }}, {{ item.courseName }}, 订单时间：{{ item.createTime | timeStr }}，状态：{{
            item.status | statusStr
          }}
        </div>
      </el-form-item>
      <el-form-item label="所有课程" v-if="review && review.userOwnCourseList">
        <div v-for="(item, index) in review.userOwnCourseList" :key="index">
          {{ item.courseId }}, {{ item.courseName }}, 关联订单：{{ item.orderId }}
        </div>
      </el-form-item>

      <el-form-item label="申请时间" v-if="review && review.courseExtendReview">
        {{ review.courseExtendReview.createTime | timeStr }}
      </el-form-item>

      <el-form-item label="内容" v-if="review && review.courseExtendReview">
        <div>{{ review.courseExtendReview.workText }}</div>
      </el-form-item>

      <el-form-item label="用户上传" v-if="review && review.courseExtendReview">
        <aidaUploader
          v-model="review.courseExtendReview.userAttach"
          :readonly="true"
          :bizType="bizType"
          :isSingleFile="false"
          :maxSize="100"
        >
        </aidaUploader>
      </el-form-item>

      <el-form-item label="大图展示" v-if="review && review.courseExtendReview">
        <div>
          <img
            style="max-width:500px;max-height:500px;margin:20px"
            v-for="(item, index) in review.courseExtendReview.userAttach"
            :key="index"
            :src="item.url"
          />
        </div>
      </el-form-item>

      <el-form-item label="">
        <span>- 以下是老师审核操作区 -</span>
      </el-form-item>

      <el-form-item label="审核" v-if="review && review.courseExtendReview">
        <el-input
          class="teacher-text"
          type="textarea"
          v-model="review.courseExtendReview.teacherText"
          :rows="8"
        ></el-input>
      </el-form-item>

      <el-form-item label="老师上传" v-if="review && review.courseExtendReview">
        <aidaUploader
          v-model="review.courseExtendReview.teacherAttach"
          :readonly="false"
          :bizType="bizType"
          :isSingleFile="false"
          :maxSize="100"
        >
        </aidaUploader>
      </el-form-item>
      <el-form-item label="换课程资产">
        <el-row class="row">
          <el-col :span="12"> 要销毁的课程id，每行一个id </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="8" class="col">
            <el-input
              type="textarea"
              :rows="5"
              v-model="removeCourseStr"
              @blur.prevent="blurToQueryCourse(removeCourseStr, 'remove')"
            ></el-input>
            <!-- <el-input v-model="item.courseId" @blur.prevent="blurToQueryCourse(item)"> </el-input> -->
          </el-col>
          <el-col :span="16" class="col"> <div style="margin-left:20px" v-html="removeCourseHtml"></div> </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="12"> 要添加的课程id，每行一个id </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="8" class="col">
            <el-input
              type="textarea"
              :rows="5"
              v-model="addCourseStr"
              @blur.prevent="blurToQueryCourse(addCourseStr, 'add')"
            ></el-input>
            <!-- <el-input v-model="item.courseId" @blur.prevent="blurToQueryCourse(item)"> </el-input> -->
          </el-col>
          <el-col :span="16" class="col"> <div style="margin-left:20px" v-html="addCourseHtml"></div> </el-col>
        </el-row>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onApprove">同意续课</el-button>
        <el-button @click="onReject">拒绝</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import msgLogger from "@/util/msgLogger";
import { aidaCourseApi } from "@/api/aidaCourseApi";
import { courseExtendApi } from "@/api/courseExtendApi";
import aidaUploader from "@/component/aidaUploader.vue";
import dayjs from "dayjs";
import { fileValidate } from "@/util/fileValidate";

export default {
  components: {
    aidaUploader,
  },
  data() {
    return {
      id: 0,
      review: null,

      bizType: "user_course_extend_review",
      removeCourseList: [],
      removeCourseStr: "",
      removeCourseHtml: "",
      addCourseList: [],
      addCourseStr: "",
      addCourseHtml: "",
    };
  },
  methods: {
    onApprove() {
      if (this.newCourseId <= 0) {
        msgLogger.warn("如果同意续课，newCourseId 必须填");
        return;
      }
      courseExtendApi
        .approve({
          courseExtendReview: this.review.courseExtendReview,
          removeCourseList: this.removeCourseList,
          addCourseList: this.addCourseList,
        })
        .then((ret) => {
          console.log("approve", ret);
          let { code, data } = ret;
          if (code == 0) {
            msgLogger.done("同意续课。。。");
            this.initData(this.id);
          } else {
            msgLogger.error(ret.msg);
          }
        })
        .catch((err) => {
          msgLogger.error(err);
        });
    },
    onReject() {
      courseExtendApi
        .reject(this.review.courseExtendReview)
        .then((ret) => {
          console.log("reject", ret);
          let { code, data } = ret;
          if (code == 0) {
            msgLogger.done("拒绝续课。。。");
            this.initData(this.id);
          } else {
            msgLogger.error(ret.msg);
          }
        })
        .catch((err) => {
          msgLogger.error(err);
        });
    },
    blurToQueryCourse(idsStr, type) {
      let fields = idsStr.replaceAll("\n", ",");
      aidaCourseApi
        .getCourseListByIds(fields)
        .then((ret) => {
          if (ret.code == 0 && ret.data) {
            if (type == "remove") {
              let htmlStr = "";
              this.removeCourseList = [];
              for (let item of ret.data) {
                htmlStr += item.courseId + "," + item.courseName + "<br />";
                this.removeCourseList.push({
                  courseId: item.courseId,
                  courseName: item.courseName,
                });
              }
              this.removeCourseHtml = htmlStr;
            } else {
              let htmlStr = "";
              this.addCourseList = [];
              for (let item of ret.data) {
                htmlStr += item.courseId + "," + item.courseName + "<br />";
                this.addCourseList.push({
                  courseId: item.courseId,
                  courseName: item.courseName,
                });
              }
              this.addCourseHtml = htmlStr;
            }
          }
        })
        .catch((err) => {
          console.log(err);
          msgLogger.warn(err);
        });
    },
    initData(id) {
      courseExtendApi.getById(id).then((ret) => {
        let { code, data } = ret;
        if (code == 0 && data) {
          this.review = data;
          if (this.review && this.review.courseExtendReview && this.review.courseExtendReview.userAttach) {
            if (this.review.courseExtendReview.userAttach) {
              this.wrapAttach(this.review.courseExtendReview.userAttach);
            }
            if (this.review.courseExtendReview.teacherAttach) {
              this.wrapAttach(this.review.courseExtendReview.teacherAttach);
            }
          }
          console.log("#", this.review);
        }
      });
    },
    wrapAttach(itemList) {
      for (let item of itemList) {
        if (item.fileType) {
          // 新数据会有fileType
          continue;
        }
        item.fileType = fileValidate.matchFileType(item.key);
      }
    },
  },
  mounted() {
    if (this.$route && this.$route.query && this.$route.query.id) {
      const id = this.$route.query.id;
      this.initData(id);
      this.id = id;
    }
  },
  filters: {
    timeStr: function(value) {
      let day = dayjs(Number(value));
      return day.format("YYYY-MM-DD hh:mm");
    },
    statusStr: function(value) {
      if (value == -5) {
        return "预创建失败";
      } else if (value == -4) {
        return "预创建";
      } else if (value == -3) {
        return "用户主动取消";
      } else if (value == -2) {
        return "已失效";
      } else if (value == -1) {
        return "取消订单";
      } else if (value == 0) {
        return "未支付";
      } else if (value == 1) {
        return "已支付";
      } else if (value == 2) {
        return "待成团";
      } else if (value == 3) {
        return "已退款";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 90%;
  .teacher-text {
    width: 80%;
  }
  .button-row {
    margin: 20px;
  }
  .user-own-course-list {
    margin: 20px;
    .row {
      padding: 10px;
      .col {
        padding: 0 10px;
      }
    }
  }
}
</style>
